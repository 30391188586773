.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-image {
  width: 350px;
  margin-bottom: 20px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .logo-image {
    width: 250px;
    margin-bottom: 20px;
  }
}

.section-container {
  background: #ffffff;
  padding: 2rem 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0 auto;
}

.section-container h2 {
  font-size: 2rem;
  color: #343a40;
  margin-bottom: 1rem;
}

.section-container ol {
  padding-left: 1.5rem;
}

.section-container li {
  font-size: 1.125rem;
  color: #495057;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;
}

.step-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.username {
  font-size: 1rem;
  font-weight: bold;
}