.user-profile {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.profile-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.profile-picture {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
}

.profile-username {
    font-size: 2em;
    margin: 0;
}

.profile-elo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 200px;
    margin-left: auto;
}

.profile-elo {
    margin: 0;
    font-size: 1.5em;
}

.win-percentage-chart {
    position: relative;
    width: 150px;
    height: 150px;
    margin-top: 10px;
}

.win-percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    font-weight: bold;
}

.user-details {
    margin-top: 20px;
    font-size: 1em;
    width: 100%;
    word-wrap: break-word;
}

  .table-custom .date-section {
    font-size: 1rem;
    font-style: italic;
  }

@media (max-width: 768px) {
    .profile-header {
        flex-direction: column;
        align-items: center;
    }

    .profile-picture {
        width: 100px;
        height: 100px;
        margin: 0 auto;
    }

    .profile-username {
        font-size: 1.5em;
    }

    .profile-elo-container {
        margin-left: 0;
    }

    .user-details {
        font-size: 1em;
        padding: 0 10px;
    }

    .win-percentage-chart {
        width: 80px;
        height: 80px;
    }

    .username {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 6ch;
    }
}

.error-message {
    color: red;
    font-weight: bold;
}

.loading-message {
    font-weight: bold;
}
  
  
  
  
  
  
  