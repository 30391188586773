.footer {
    padding: 15px 0;
    border-top: 1px solid #ddd;
}

.footer-icon {
    font-size: 1.5rem;
    color: #000;
}

.footer-icon:hover {
    color: #007bff;
}

.footer .me-3 {
    margin-right: 1rem;
}

.footer .ms-3 {
    margin-left: 1rem;
}

.footer .row {
    margin-bottom: 1rem;
}

.footer-repo {
    font-size: 1rem;
    color: #000;
}

.footer-repo:hover {
    color: #007bff;
}
