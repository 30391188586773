.user-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 50px;
}

.form-container h2 {
  margin-bottom: 1rem;
  text-align: center;
}

@media (max-width: 600px) {
  .form-container {
      width: 90%;
  }
}
