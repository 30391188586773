.form-container {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
}

.form-header {
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

.submit-button {
  width: 100%;
}

.action-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.text-danger {
  font-size: 0.875rem;
  color: #dc3545;
  margin-top: 0.25rem;
}

.custom-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.custom-dropdown-button img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.custom-dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.custom-dropdown-menu.show {
  display: block;
}

.custom-dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.custom-dropdown-item img {
  width: 200px;
  height: 200px;
  margin-right: 10px;
}

.custom-dropdown-item:hover {
  background-color: #f0f0f0;
}

.table-custom .winner-section {
  background-color: #d5e3ff;
  color:#4171d6;
  font-size: 1.3rem;
}

.table-custom .loser-section {
  background-color: #ffd8d9;
  color:#e84057;
  font-size: 1.3rem;
}
  
.table-custom .score-section {
  background-color: #ffffff;
  font-size: 1.4rem;
}
  
.table-custom .date-section {
  font-size: 1rem;
  font-style: italic;
}
  
.error-message {
  color: #dc3545;
}
  
.username {
  font-size: 1rem;
}

.table-custom th,
.table-custom td {
  text-align: center;
}

.table-custom .compact-column {
  width: 10%;
}

.table-custom .username-column {
  width: 25%;
  white-space: nowrap;
}

.table-custom .username-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 6ch;
  }
}

.table-custom .flex-column {
  flex-grow: 1;
  text-align: left;
}

.table-custom {
  width: 100%;
}

.table-custom td,
.table-custom th {
  padding: 0.5rem;
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.page-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.page-item.active {
  background-color: #007bff;
  color: #fff;
}

.page-item:hover {
  background-color: #e9ecef;
}

.page-item:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
